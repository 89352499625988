{
  "Welcome to the practical retrofit early-stage survey tool (PRESS).": "Welcome to the practical retrofit early-stage survey tool (PRESS).",
  "welcome-text": "PRESS enables the collection of relevant information that can be used to help determine appropriate retrofit solutions at the planning stage in the drive towards a net-zero future.\n\nThe PRESS tool has been designed to be used by non-experts enable the collection of relevant data to inform the decision making process at the planning stage of a retrofit.",
  "Get started": "Get started",
  "Create new": "Create new",
  "Already a member?": "Already a member? ",
  "Need to get in touch?": "Need to get in touch? ",
  "Not a member yet?": "Not a member yet? ",
  "Sign Up": "Sign Up",
  "Forgot Password?": "Forgot Password?",
  "Log on": "Log on",
  "Save and Continue": "Save and Continue",
  "Back": "Back",
  "Resubmit": "Resubmit",
  "Submit": "Submit",
  "Enter password": "Enter password",
  "Confirm password": "Confirm password",
  "Password": "Password",
  "Enter email": "Enter email",
  "Email": "Email",
  "Registration email sent": "Registration email sent",
  "Contact us": "Contact us",
  "Back to login": "Back to login",
  "Forgot Password": "Forgot Password",
  "Forgot password email sent": "Forgot password email sent",
  "set-new-password": "We've sent an email to the address you provided.\n\nClick on the link in the email to set a new password.",
  "we-sent-a-link": "We've sent an email to the address you provided.\n\nClick on the link in the email to confirm the address and log in.",
  "PRESS Assessment": "PRESS Assessment",
  "Edit/view existing": "Edit/view existing",
  "Enter data for Practical Retrofit Early-Stage Survey (PRESS)": "Enter data for Practical Retrofit Early-Stage Survey (PRESS)",
  "Enter Address line 1": "Enter Address line 1",
  "Address 1": "Address 1",
  "Enter Address line 2": "Enter Address line 2",
  "Address 2": "Address 2",
  "Enter county": "Enter county",
  "County": "County",
  "Enter town": "Enter town",
  "Town": "Town",
  "Enter postcode": "Enter postcode",
  "Postcode": "Postcode",
  "PRESS Information": "PRESS Information",
  "Appliances and lighting": "Appliances and lighting",
  "Building construction": "Building construction",
  "Building location": "Building location",
  "Energy bills": "Energy bills",
  "Draw and annotate a floor plan for each floor of the home, including relevant notes associated with the checklists below. Take a photo of your drawing and upload it below.": "Draw and annotate a floor plan for each floor of the home, including relevant notes associated with the checklists below. Take a photo of your drawing and upload it below.",
  "Potential retrofit": "Potential retrofit",
  "9. Residents": "9. Residents",
  "Heating": "Heating",
  "1. Details": "1. Details",
  "4. Ventilation": "4. Ventilation",
  "Click on a section to complete": "Click on a section to complete",
  "Need more info?": "Need more info? ",
  "See guidance": "See guidance",
  "Edit/view existing PRESS Survey": "Edit/view existing PRESS Survey",
  "Search for address": "Search for address",
  "Surveyor Name": "Surveyor Name",
  "Enter surveyor name": "Enter surveyor name",
  "Role": "Role",
  "Enter role": "Enter role",
  "Organisation name": "Organisation name",
  "Enter organisation name": "Enter organisation name",
  "Property address 1": "Property address 1",
  "Enter property address 1": "Enter property address 1",
  "Property address 2": "Property address 2",
  "Enter property address 2": "Enter property address 2",
  "North": "North",
  "North East": "North East",
  "East": "East",
  "West": "West",
  "South": "South",
  "South East": "South East",
  "South West": "South West",
  "North West": "North West",
  "2.1. Front facade orientation": "2.1. Front facade orientation",
  "Please select one": "Please select one",
  "2.2.  Urban context": "2.2.  Urban context",
  "2.3. Exposure": "2.3. Exposure",
  "Additional notes": "Additional notes",
  "For example complex roof layouts, access issues due to location for deliveries, etc.": "For example complex roof layouts, access issues due to location for deliveries, etc.",
  "Add photos": "Add photos",
  "Add photos of house number, front façade, house and its surroundings etc": "Add photos of house number, front façade, house and its surroundings etc",
  "Choose File": "Choose File",
  "Urban": "Urban",
  "Sub-urban": "Sub-urban",
  "Rural": "Rural",
  "Open": "Open",
  "Normal": "Normal",
  "Dense/Enclosed": "Dense/Enclosed",
  "Add notes": "Add notes",
  "Please select all that apply and add notes below": "Please select all that apply and add notes below",
  "Please select all that apply": "Please select all that apply",
  "Presence and date of extensions": "Presence and date of extensions/conservatories/garages, details of wall thickness, location of thermal bridges, quality of property in general, locations of damp, renewable energy supply such as PV, etc.",
  "3.1. Year of construction": "3.1. Year of construction",
  "3.2 Floor area": "3.2 Floor area",
  "3.3 Type of construction": "3.3 Type of construction",
  "3.4 Dwelling type": "3.4 Dwelling type",
  "3.5 Existing retrofit improvements": "3.5 Existing retrofit improvements",
  "3.6 Location of adjoining properties": "3.6 Location of adjoining properties",
  "Pre 1919": "Pre 1919",
  "1920 - 1944": "1920 - 1944",
  "1945 - 1964": "1945 - 1964",
  "1965 - 1977": "1965 - 1977",
  "1978 - 1984": "1978 - 1984",
  "1985 - 1994": "1985 - 1994",
  "1994 - 2006": "1994 - 2006",
  "After 2006": "After 2006",
  "Total internal floor area (m²). If unknown enter 0.": "Total internal floor area (m²). If unknown enter 0.",
  "Enter floor area": "Enter floor area",
  "Number of bedrooms. If unknown enter 0.": "Number of bedrooms. If unknown enter 0.",
  "Enter number": "Enter number",
  "Enter other details": "Enter other details",
  "Other details": "Other details",
  "Steel frame (low thermal mass)": "Steel frame (low thermal mass)",
  "Timber frame (low thermal mass)": "Timber frame (low thermal mass)",
  "Cavity wall (medium Thermal mass)": "Cavity wall (medium thermal mass)",
  "Solid wall (high thermal mass)": "Solid wall (high thermal mass)",
  "Modern method of construction": "Modern method of construction",
  "Other - Specify": "Other - Specify",
  "1 storey house / bungalow": "1 storey house / bungalow",
  "2 storey house": "2 storey house",
  "3+ storey house": "3+ storey house",
  "Purpose built flat": "Purpose built flat",
  "Converted flat": "Converted flat",
  "external_wall_insulation": "external_wall_insulation",
  "Cavity filled insulation": "Cavity filled insulation",
  "Double glazed windows": "Double glazed windows",
  "Double glazed windows (percentage). If unknown enter 0.": "Double glazed windows (percentage). If unknown enter 0.",
  "Enter percentage": "Enter percentage",
  "Loft insulation": "Loft insulation",
  "Loft insulation approx. thickness (millimetres). If unknown enter 0.": "Loft insulation approx. thickness (millimetres). If unknown enter 0.",
  "Enter approx thickness": "Enter approx thickness",
  "loft hatch insulation": "loft hatch insulation",
  "Above": "Above",
  "Below": "Below",
  "Left": "Left",
  "Right": "Right",
  "None": "None",
  "Energy source": "Energy source ",
  "Electricity tariff type": "Electricity tariff type",
  "Enter electricity tariff type": "Enter electricity tariff type",
  "Energy type": "Energy type",
  "Electricity": "Electricity",
  "Gas": "Gas",
  "Oil": "Oil",
  "Coal": "Coal",
  "Other": "Other",
  "Payment on delivery frequency": "Payment on delivery frequency",
  "Enter payment frequency": "Enter payment frequency",
  "Monthly direct debit": "Monthly direct debit",
  "Variable monthly direct debit": "Variable monthly direct debit",
  "Quarterly direct debit": "Quarterly direct debit",
  "Prepayment meter": "Prepayment meter",
  "Pay on delivery (note frequency)": "Pay on delivery (note frequency)",
  "Payment": "Payment",
  "Costs": "Costs",
  "Please enter the cost based on the payment period selected above": "Please enter the cost based on the payment period selected above",
  "Enter energy bill cost (£)": "Enter energy bill cost (£)",
  "Summer (£)": "Summer (£)",
  "Winter (£)": "Winter (£)",
  "Add energy source": "Add energy source",
  "For example scenario for hours home during weekday/weekends, etc.": "For example scenario for hours home during weekday/weekends, etc.",
  "Under 16 years": "Under 16 years",
  "16 - 25 years": "16 - 25 years",
  "26 - 49 years": "26 - 49 years",
  "50 - 65 years": "50 - 65 years",
  "Over 65 years": "Over 65 years",
  "Resident": "Resident",
  "Weekday hours at home": "Weekday hours at home",
  "Weekend hours at home": "Weekend hours at home",
  "Flexible working": "Flexible working",
  "Morning": "Morning",
  "Afternoon": "Afternoon",
  "Evening": "Evening",
  "Night": "Night",
  "Add person": "Add person",
  "6.1. Lighting": "6.1 Lighting",
  "Enter number of bulbs in the home. If unknown or not present enter 0.": "Enter number of bulbs in the home. If unknown or not present enter 0.",
  "LED bulb": "LED bulb",
  "6.4. Smart meter": "6.4. Smart meter",
  "Compact fluorescent bulb": "Compact fluorescent bulb",
  "Fluorescent bulb": "Fluorescent bulb",
  "Halogen bulb": "Halogen bulb",
  "Incandescent bulb": "Incandescent bulb",
  "6.2. Cooking": "6.2. Cooking",
  "Enter number of times used per week. If unknown or not present enter 0.": "Enter number of times used per week. If unknown or not present enter 0.",
  "Electric hob": "Electric hob",
  "Gas hob": "Gas hob",
  "Electric oven": "Electric oven",
  "Gas oven": "Gas oven",
  "Microwave": "Microwave",
  "6.3. Appliances": "6.3. Appliances",
  "Dishwasher": "Dishwasher",
  "Tumble dryer": "Tumble dryer",
  "Washing machine": "Washing machine",
  "Specify other": "Specify other",
  "Choose file": "Choose file",
  "Uploading...": "Uploading...",
  "Click to try again": "Click to try again",
  "This file is too large. The maximum file size accepted is 10MB.": "This file is too large. The maximum file size accepted is 10MB.",
  "This file is empty": "This file is empty",
  "Sorry, there was a problem uploading the file.": "Sorry, there was a problem uploading the file.",
  "10.1. Building elements": "10.1. Building elements",
  "Wall thickness": "Wall thickness",
  "Position and size of windows and doors": "Position and size of windows and doors",
  "Position and size of loft hatch": "Position and size of loft hatch",
  "Changes to layouts and extensions": "Changes to layouts and extensions",
  "Position of boiler": "Position of boiler",
  "Position and size of radiators": "Position and size of radiators",
  "Position secondary heating": "Position secondary heating",
  "Position of vents and fans": "Position of vents and fans",
  "Position and number of bulbs": "Position and number of bulbs",
  "Position and types of meters": "Position and types of meters",
  "Position and type of fuel storage": "Position and type of fuel storage",
  "10.2. Heating and ventilation system": "10.2. Heating and ventilation system",
  "10.3. Lightings": "10.3. Lightings",
  "10.4. Energy meters": "10.4. Energy meters",
  "Add photos of facades, windows, external doors, roof finish and loft hatch": "Add photos of facades, windows, external doors, roof finish and loft hatch",
  "Add photos of light fittings, electric appliances and cooking appliances": "Add photos of light fittings, electric appliances and cooking appliances",
  "Add photos of energy meters, distribution board and energy bills": "Add photos of energy meters, distribution board and energy bills",
  "Add photo of floor plan": "Add photo of floor plan",
  "Other energy type": "Other energy type",
  "Enter other energy type details": "Enter other energy type details",
  "roof": "Roof",
  "building_fabric": "Building fabric",
  "heating_system": "Heating system",
  "renewable_energy_supply": "Renewable energy supply",
  "ventilation": "Ventilation",
  "whole_house_retrofit": "Whole house retrofit",
  "energy_reduction": "Energy reduction",
  "carbon_reduction": "Carbon reduction",
  "cost_reduction": "Cost reduction",
  "improve_internal_environment": "Improve internal environment",
  "improve_external_environment": "Improve external environment",
  "improve_health_conditions": "Improve health conditions",
  "8.1. Possible modifications required": "8.1. Possible modifications required",
  "Please drag and drop in order of importance": "Please drag and drop in order of importance",
  "8.2. Project targets": "8.2. Project targets",
  "8.3. Available data": "8.3. Available data",
  "Add photos of outdoor spaces, damp/mould growth and quality of the house": "Add photos of outdoor spaces, damp/mould growth and quality of the house",
  "Add photos of heating systems showing brand and model details, radiators, air source valves, secondary heating elements, bath, shower and hot water tank": "Add photos of heating systems showing brand and model details, radiators, air source valves, secondary heating elements, bath, shower and hot water tank",
  "For example heating control type and location, TVRs, thermostats, timers, rooms where no space heating, etc.": "For example heating control type and location, TVRs, thermostats, timers, rooms where no space heating, etc.",
  "5.1. Space heating - Primary": "5.1. Space heating - Primary",
  "5.1.1. Energy source": "5.1.1. Energy source",
  "Electric": "Electric",
  "Biomass": "Biomass",
  "Conventional boiler": "Conventional boiler",
  "System boiler (with DHW tank)": "System boiler (with DHW tank)",
  "Combi boiler (instant DHW)": "Combi boiler (instant DHW)",
  "Air source heat pump": "Air source heat pump",
  "Ground source heat pump": "Ground source heat pump",
  "Biomass boiler": "Biomass boiler",
  "Storage heaters": "Storage heaters",
  "5.1.3. Heating delivery": "5.1.3. Heating delivery",
  "Radiator/s": "Radiator/s",
  "Underfloor Heating": "Underfloor Heating",
  "Air Source": "Air Source",
  "5.2. Space heating - Secondary": "5.2. Space heating - Secondary",
  "5.2.1. Energy source": "5.2.1. Energy source",
  "5.2.2. Heating type": "5.2.2. Heating type",
  "Fireplace": "Fireplace",
  "Stove": "Stove",
  "Electric heater": "Electric heater",
  "Oil filled radiators": "Oil filled radiators",
  "Insert the number of each and mark them on your floor plan drawing. If not present enter 0.": "Insert the number of each and mark them on your floor plan drawing. If not present enter 0.",
  "5.2.3. Frequency of use": "5.2.3. Frequency of use",
  "Enter number of times they are used per week. If unknown or not present enter 0.": "Enter number of times they are used per week. If unknown or not present enter 0.",
  "5.3. Water heating": "5.3. Water heating",
  "5.3.1. Demand source": "5.3.1. Demand source",
  "5.3.2. Water heating type": "5.3.2. Water heating type",
  "5.4. Heating settings": "5.4. Heating settings",
  "Bath": "Bath",
  "Shower": "Shower",
  "Hand washing clothes": "Hand washing clothes",
  "Hand washing dishes": "Hand washing dishes",
  "Electric shower": "Electric shower",
  "Boiler": "Boiler - same as space heating",
  "Hot water tank": "Hot water tank",
  "Immersion heater": "Immersion heater",
  "Hot water tank capacity": "Hot water tank capacity",
  "Please enter details for occupied homes only. If unoccupied please leave blank.": "Please enter details for occupied homes only. If unoccupied please leave blank.",
  "Heating months from:": "Heating months\nfrom:",
  "to:": "to:",
  "Enter month": "Enter month",
  "Heating times winter from (time of day):": "Heating times winter\nfrom (time of day):",
  "Heating times summer from (time of day):": "Heating times summer\nfrom (time of day):",
  "Enter time": "Enter time",
  "Enter temperature": "Enter temperature",
  "Heating temperature winter": "Heating temperature winter",
  "Heating temperature summer": "Heating temperature summer",
  "Add photos of ventilation elements": "Add photos of ventilation elements",
  "For example other ventilation, trickle vents, etc.": "For example other ventilation, trickle vents, etc.",
  "Cooking": "Cooking",
  "Having a bath or shower": "Having a bath or shower",
  "Washing drying clothes": "Washing drying clothes",
  "Smoking": "Smoking",
  "Always": "Always",
  "Never": "Never",
  "4.1. Ventilation": "4.1. Ventilation",
  "Select when the resident would generally open windows or doors": "Select when the resident would generally open windows or doors",
  "4.2. Additional ventilation": "4.2. Additional ventilation",
  "Insert the number of each and mark them on your floor plan drawing": "Insert the number of each and mark them on your floor plan drawing",
  "4.3. Other key features": "4.3. Other key features",
  "Extraction fan": "Extraction fan",
  "Cooker hood": "Cooker hood",
  "Cavity vents": "Cavity vents",
  "Positive input ventilation": "Positive input ventilation",
  "All house - MVHR": "All house - MVHR",
  "Room - MVHR": "Room - MVHR",
  "Chimneys and open fireplaces": "Chimneys and open fireplaces",
  "Damaged windows": "Damaged windows",
  "Uninsulated loft hatch": "Uninsulated loft hatch",
  "Insulated loft hatch": "Insulated loft hatch",
  "Door letterbox": "Door letterbox",
  "Cat flap": "Cat flap",
  "You have already submitted this PRESS Assessment, click Resubmit to update.": "You have already submitted this PRESS Assessment, click Resubmit to update.",
  "You have completed all sections and are ready to submit.": "You have completed all sections and are ready to submit.",
  "Your PRESS Assessment has been successfully submitted.": "Your PRESS Assessment has been successfully submitted.",
  "Download report": "Download report",
  "Download Images": "Download Images",
  "5. Space heating": "5. Space heating",
  "Generating...": "Generating...",
  "Add the number of residents and then fill in their details": "Add the number of residents and then fill in their details",
  "Add the number of energy sources and then fill in the details": "Add the number of energy sources and then fill in the details",
  "Guidance": "Guidance",
  "Guidance 2.1": "The orientation of the front façade helps to identify the potential for solar generation of all orientations of the property.",
  "Guidance 2.2": "The urban context may impact on the routes for access, overshading, supply chain availability, etc.",
  "Guidance 2.3": "The exposure of the building and site may impact on wind exposure, solar radiation, overshading, etc",
  "Guidance 3.1": "The year of construction can help to determine the building fabric specifications. The different age ranges listed on the form refer to major changes to the UK Building Regulations.",
  "Guidance 3.2": "An approximate internal floor area may help the assessor to evaluate energy costs per floor area, enabling results between different houses can be compared like for like.",
  "Guidance 3.3": "The type of construction has an impact on the thermal mass of the building as well as on the likely airtightness of the building.",
  "Guidance 3.4": "The dwelling type has an impact on the heating demand of the building.",
  "Guidance 3.6": "The location of adjoining properties has an impact on the heating demand as the more neighbouring walls the building has, the less the heat loss through these walls is likely to be.",
  "Architectual drawings": "Architectual drawings",
  "Building fabric specifications": "Building fabric specifications",
  "Systems specifications": "Systems specifications",
  "Broadband or internet availability": "Broadband or internet availability"
}
