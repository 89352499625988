/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import WrappedField from '../forms/wrapped-field';
import Checkbox from '../../framework-components/select/checkbox';
import FileUpload from '../../framework-components/file-upload';
import { Section4part2, TOOLTIP_STYLE } from './guidance';
import InfoIcon from '../assets/InfoIcon';

interface Values {
  ventilation: {
    additional_notes: string;
  };
  ventilation_times: {
    cooking: boolean;
    having_a_bath_or_shower: boolean;
    washing_drying_clothes: boolean;
    smoking: boolean;
    always: boolean;
    never: boolean;
  };
  additional_ventilation: {
    extraction_fan: number;
    cooker_hood: number;
    cavity_vents: number;
    positive_input_ventilation: number;
    all_house_mvhr: number;
    room_mvhr: number;
  };
  other_key_features: {
    chimneys_and_open_fireplaces: number;
    damaged_windows: number;
    loft_hatch: number;
    insulated_loft_hatch: number;
    door_letterbox: number;
    catflap: number;
    other: number;
    other_details: string;
  };
}

const ventilation_times_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Cooking'),
    value: 'ventilation_times.cooking',
  },
  {
    label: i18next.t('Having a bath or shower'),
    value: 'ventilation_times.having_a_bath_or_shower',
  },
  {
    label: i18next.t('Washing drying clothes'),
    value: 'ventilation_times.washing_drying_clothes',
  },
  {
    label: i18next.t('Smoking'),
    value: 'ventilation_times.smoking',
  },
  {
    label: i18next.t('Always'),
    value: 'ventilation_times.always',
  },
  {
    label: i18next.t('Never'),
    value: 'ventilation_times.never',
  },
];

const Ventilation: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/ventilation/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/ventilation/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(`/space-heating?survey_id=${currentSurveyID?.survey_id}`);
        }
      },
      [navigate, submitForm]
    );
  }
  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('4. Ventilation')}</div>
          </h1>
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              other_key_features: {
                chimneys_and_open_fireplaces:
                  data.other_key_features.chimneys_and_open_fireplaces,
                damaged_windows: data.other_key_features.damaged_windows,
                loft_hatch: data.other_key_features.loft_hatch,
                insulated_loft_hatch:
                  data.other_key_features.insulated_loft_hatch,
                door_letterbox: data.other_key_features.door_letterbox,
                catflap: data.other_key_features.catflap,
                other: data.other_key_features.other || 0,
                other_details: data.other_key_features.other_details,
              },
              ventilation_times: {
                cooking: Boolean(data.ventilation_times.cooking),
                having_a_bath_or_shower: Boolean(
                  data.ventilation_times.having_a_bath_or_shower
                ),
                washing_drying_clothes: Boolean(
                  data.ventilation_times.washing_drying_clothes
                ),
                smoking: Boolean(data.ventilation_times.smoking),
                always: Boolean(data.ventilation_times.always),
                never: Boolean(data.ventilation_times.never),
              },
            }}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('4.1. Ventilation')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Select when the resident would generally open windows or doors'
                      )}
                    </em>
                  </div>
                  <Checkbox
                    key={ventilation_times_options[0].label.toString()}
                    option={ventilation_times_options[0]}
                    checked={Boolean(values.ventilation_times.cooking)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={ventilation_times_options[1].label.toString()}
                    option={ventilation_times_options[1]}
                    checked={Boolean(
                      values.ventilation_times.having_a_bath_or_shower
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={ventilation_times_options[2].label.toString()}
                    option={ventilation_times_options[2]}
                    checked={Boolean(
                      values.ventilation_times.washing_drying_clothes
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={ventilation_times_options[3].label.toString()}
                    option={ventilation_times_options[3]}
                    checked={Boolean(values.ventilation_times.smoking)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={ventilation_times_options[4].label.toString()}
                    option={ventilation_times_options[4]}
                    checked={Boolean(values.ventilation_times.always)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                    errors={errors.ventilation_times?.always}
                  />
                  <Checkbox
                    key={ventilation_times_options[5].label.toString()}
                    option={ventilation_times_options[5]}
                    checked={Boolean(values.ventilation_times.never)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                    errors={errors.ventilation_times?.never}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('4.2. Additional ventilation')}
                    <span className="pl-1" data-tooltip-id={'section4part2'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section4part2'}
                        variant="info"
                        openOnClick
                      >
                        <Section4part2 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Insert the number of each and mark them on your floor plan drawing. If not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.extraction_fan"
                    label={i18next.t('Extraction fan')}
                    errors={errors.additional_ventilation?.extraction_fan}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.cooker_hood"
                    label={i18next.t('Cooker hood')}
                    errors={errors.additional_ventilation?.cooker_hood}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.cavity_vents"
                    label={i18next.t('Cavity vents')}
                    errors={errors.additional_ventilation?.cavity_vents}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.positive_input_ventilation"
                    label={i18next.t('Positive input ventilation')}
                    errors={
                      errors.additional_ventilation?.positive_input_ventilation
                    }
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />{' '}
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.all_house_mvhr"
                    label={i18next.t('All house - MVHR')}
                    errors={errors.additional_ventilation?.all_house_mvhr}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />{' '}
                  <WrappedField
                    className="py-2"
                    name="additional_ventilation.room_mvhr"
                    label={i18next.t('Room - MVHR')}
                    errors={errors.additional_ventilation?.room_mvhr}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('4.3. Other key features')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Insert the number of each and mark them on your floor plan drawing. If not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="other_key_features.chimneys_and_open_fireplaces"
                    label={i18next.t('Chimneys and open fireplaces')}
                    placeholder={i18next.t('Enter number')}
                    errors={
                      errors.other_key_features?.chimneys_and_open_fireplaces
                    }
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.damaged_windows"
                    label={i18next.t('Damaged windows')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.damaged_windows}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.loft_hatch"
                    label={i18next.t('Uninsulated loft hatch')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.loft_hatch}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.insulated_loft_hatch"
                    label={i18next.t('Insulated loft hatch')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.insulated_loft_hatch}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.door_letterbox"
                    label={i18next.t('Door letterbox')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.door_letterbox}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.catflap"
                    label={i18next.t('Cat flap')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.catflap}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.other"
                    label={i18next.t('Other - Specify')}
                    placeholder={i18next.t('Enter number')}
                    errors={errors.other_key_features?.other}
                    defaultValue={0}
                  />
                  <WrappedField
                    className="py-2"
                    name="other_key_features.other_details"
                    label={i18next.t('Other details')}
                    placeholder={i18next.t('Enter details')}
                    errors={errors.other_key_features?.other_details}
                    defaultValue={''}
                    disabled={values.other_key_features.other < 1}
                  />
                  <TextArea
                    id="ventilation.additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t(
                      'For example other ventilation, trickle vents, etc.'
                    )}
                    name="ventilation.additional_notes"
                    onChange={handleChange}
                    defaultValue={values && values.ventilation.additional_notes}
                    placeholder={i18next.t('Add notes')}
                  />
                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of ventilation elements'
                    )}
                    fieldName={'ventilation'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="VENTILATION"
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/building-construction/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={40}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Ventilation;
