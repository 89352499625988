/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers, FieldArray, getIn } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { apiRequest, useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Radio from '../../framework-components/select/radio';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import WrappedField from '../forms/wrapped-field';
import { AddButton } from '../../framework-components/button';
import FileUpload from '../../framework-components/file-upload';

interface EnergySources {
  source_id: string;
  energy_type: string;
  energy_type_other_details: string;
  electricity_tariff_type: string;
  payment_type: string;
  pay_on_delivery_frequency: string;
  winter_cost: string;
  summer_cost: string;
}

interface Values {
  additional_notes: string;
  energy_sources: Array<EnergySources>;
}

const energy_type_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Electricity'),
    value: 'ELECTRICITY',
  },
  {
    label: i18next.t('Gas'),
    value: 'GAS',
  },
  {
    label: i18next.t('Oil'),
    value: 'OIL',
  },
  {
    label: i18next.t('Coal'),
    value: 'COAL',
  },
  {
    label: i18next.t('Other'),
    value: 'OTHER',
  },
];

const payment_type_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Monthly direct debit'),
    value: 'MONTHLY_DIRECT_DEBIT',
  },
  {
    label: i18next.t('Variable monthly direct debit'),
    value: 'VARIABLE_MONTHLY_DIRECT_DEBIT',
  },
  {
    label: i18next.t('Quarterly direct debit'),
    value: 'QUARTERLY_DIRECT_DEBIT',
  },
  {
    label: i18next.t('Prepayment meter'),
    value: 'PREPAYMENT_METER',
  },
  {
    label: i18next.t('Pay on delivery (note frequency)'),
    value: 'PAY_ON_DELIVERY',
  },
];

const EnergyBills: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/energy_bills/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/energy_bills/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(
            `/potential-retrofit?survey_id=${currentSurveyID?.survey_id}`
          );
        }
      },
      [navigate, submitForm]
    );
  }

  const ENERGY_TYPES = {
    ELECTRICITY: 'ELECTRICITY' as const,
    OTHER: 'OTHER' as const,
  };
  const ENERGY_PAYMENTS = { PAY_ON_DELIVERY: 'PAY_ON_DELIVERY' as const };

  const addSource = useCallback(() => {
    apiRequest(
      `/api/survey/${currentSurveyID?.survey_id}/energy_bills/source/add_blank/`,
      {
        method: 'POST',
      }
    ).then(() => mutate());
  }, [currentSurveyID?.survey_id, mutate]);

  const deleteSource = useCallback(
    (blockId: string) => {
      apiRequest(
        `/api/survey/${currentSurveyID?.survey_id}/energy_bills/source/delete/${blockId}/`,
        {
          method: 'POST',
        }
      ).then(() => mutate());
    },
    [currentSurveyID?.survey_id, mutate]
  );

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('7. Energy bills')}</div>
          </h1>
          <em>
            {i18next.t(
              'Add the number of energy sources and then fill in the details'
            )}
          </em>
          <Formik<Values>
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <FieldArray name="energy_sources">
                    {() => (
                      <div>
                        {values.energy_sources.length > 0 &&
                          values.energy_sources.map((energy_source, index) => (
                            <div
                              className="bg-gray-100 p-3 rounded my-6"
                              key={index}
                            >
                              <div className="flex justify-between align-top">
                                <div className="text-2xl font-semibold text-gray-900">
                                  {i18next.t('Energy source')} {index + 1}
                                </div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    deleteSource(energy_source.source_id)
                                  }
                                >
                                  x
                                </button>
                              </div>
                              <div className="text-lg font-bold p-0 m-0">
                                {i18next.t('Energy type')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                <em>{i18next.t('Please select one')}</em>
                              </div>
                              <Radio
                                name={`energy_sources.${index}.energy_type`}
                                options={energy_type_options}
                                onChange={(value) =>
                                  setFieldValue(
                                    `energy_sources.${index}.energy_type`,
                                    value
                                  )
                                }
                                errors={getIn(
                                  errors,
                                  `energy_sources[${index}].energy_type`
                                )}
                                defaultChecked={
                                  values &&
                                  values.energy_sources[index].energy_type
                                }
                              />
                              <WrappedField
                                className="pb-2"
                                name={`energy_sources.${index}.energy_type_other_details`}
                                label={i18next.t('Other energy type')}
                                placeholder={i18next.t(
                                  'Enter other energy type details'
                                )}
                                defaultValue={
                                  values &&
                                  values.energy_sources[index]
                                    .energy_type_other_details
                                }
                                disabled={
                                  values.energy_sources[index].energy_type !==
                                  ENERGY_TYPES.OTHER
                                }
                              />
                              <WrappedField
                                className="pb-2"
                                name={`energy_sources.${index}.electricity_tariff_type`}
                                label={i18next.t('Electricity tariff type')}
                                placeholder={i18next.t(
                                  'Enter electricity tariff type'
                                )}
                                defaultValue={
                                  values &&
                                  values.energy_sources[index]
                                    .electricity_tariff_type
                                }
                                disabled={
                                  values.energy_sources[index].energy_type !==
                                  ENERGY_TYPES.ELECTRICITY
                                }
                              />
                              <div className="text-lg font-bold p-0 m-0 pt-3">
                                {i18next.t('Payment')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                <em>{i18next.t('Please select one')}</em>
                              </div>
                              <Radio
                                name={`energy_sources.${index}.payment_type`}
                                options={payment_type_options}
                                onChange={(value) =>
                                  setFieldValue(
                                    `energy_sources.${index}.payment_type`,
                                    value
                                  )
                                }
                                errors={getIn(
                                  errors,
                                  `energy_sources[${index}].payment_type`
                                )}
                                defaultChecked={
                                  values &&
                                  values.energy_sources[index].payment_type
                                }
                              />
                              <WrappedField
                                className="pb-2"
                                name={`energy_sources.${index}.pay_on_delivery_frequency`}
                                label={i18next.t(
                                  'Payment on delivery frequency'
                                )}
                                placeholder={i18next.t(
                                  'Enter payment frequency'
                                )}
                                defaultValue={
                                  values &&
                                  values.energy_sources[index]
                                    .pay_on_delivery_frequency
                                }
                                disabled={
                                  values.energy_sources[index].payment_type !==
                                  ENERGY_PAYMENTS.PAY_ON_DELIVERY
                                }
                              />
                              <div className="text-lg font-bold p-0 m-0">
                                {i18next.t('Costs')}
                              </div>
                              <div className="text-base font-normal p-0 m-0 pb-1">
                                <em>
                                  {i18next.t(
                                    'Please enter the cost based on the payment period selected above'
                                  )}
                                </em>
                              </div>
                              <WrappedField
                                className="pb-2"
                                name={`energy_sources.${index}.winter_cost`}
                                label={i18next.t('Winter (£)')}
                                placeholder={i18next.t(
                                  'Enter energy bill cost (£)'
                                )}
                                defaultValue={
                                  values &&
                                  values.energy_sources[index].winter_cost
                                }
                              />
                              <WrappedField
                                className="pb-2"
                                name={`energy_sources.${index}.summer_cost`}
                                label={i18next.t('Summer (£)')}
                                placeholder={i18next.t(
                                  'Enter energy bill cost (£)'
                                )}
                                defaultValue={
                                  values &&
                                  values.energy_sources[index].summer_cost
                                }
                              />
                            </div>
                          ))}
                        <AddButton
                          className="mt-3 mb-3"
                          label={i18next.t('Add energy source')}
                          type="button"
                          onClick={addSource}
                        />
                      </div>
                    )}
                  </FieldArray>

                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of energy meters, distribution board and energy bills'
                    )}
                    fieldName={'energy'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="ENERGY_BILLS"
                  />

                  <TextArea
                    id="additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t('')}
                    name="bills.additional_notes"
                    onChange={handleChange}
                    defaultValue={values && values.additional_notes}
                    placeholder={i18next.t('Add notes')}
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/appliances-and-lighting/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={70}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EnergyBills;
